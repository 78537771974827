import 'twin.macro';
import StructuredText from '@datocms/MyStructuredText';
import { linkFocusRing } from '@styled/Link';
import StyledSentence from '@styled/Sentence';
import Link from 'components/SmartLink';
import {
	sectionTitle,
	sectionSupertitle,
	invertedLimeTextGradient,
} from 'styles';

const CenteredHeader = ({ supertitle, title, description, hrefId }) => {
	return (
		<div tw="space-y-6">
			<h2 tw="space-y-2">
				{supertitle && (
					<div tw="flex justify-center">
						<span css={[sectionSupertitle, invertedLimeTextGradient]}>
							{supertitle}
						</span>
					</div>
				)}
				<Link
					href={`#${hrefId}`}
					tw="text-center"
					css={[sectionTitle, linkFocusRing]}>
					<StyledSentence>{title}</StyledSentence>
				</Link>
			</h2>
			{description && (
				<div tw="prose prose-lg text-xl text-gray-500 leading-8">
					<StructuredText data={description} />
				</div>
			)}
		</div>
	);
};

export default CenteredHeader;
