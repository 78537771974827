// https://tailwindui.com/components/marketing/sections/faq-sections#component-8017f4faee579f7ca518cdde140d4689

import tw, { styled } from 'twin.macro';
import StructuredText from '@datocms/MyStructuredText';
import Header from '@sections/OffsetHeader';
import StyledSentence from '@styled/Sentence';
import { outerContainer } from 'styles';
import type { ItemProps, FaqProps } from './types';

const Question = tw.dt`text-lg leading-6 font-semibold text-gray-900`;
const Answer = tw.dd`mt-2`;

const Item = ({ question, answer }: ItemProps) => {
	return (
		<div
			key={question}
			itemScope
			itemProp="mainEntity"
			itemType="https://schema.org/Question">
			<Question itemProp="name">{question}</Question>
			<Answer
				itemScope
				itemProp="acceptedAnswer"
				itemType="https://schema.org/Answer">
				<div itemProp="text" css={tw`prose max-w-none text-gray-500`}>
					<StructuredText data={answer} />
				</div>
			</Answer>
		</div>
	);
};

const Items = tw.div`mt-12 lg:(mt-0 col-span-2)`;
const OffsetGrid = tw.div`lg:(grid grid-cols-3 gap-8)`;
const containerSpacing = tw`py-16 px-4 sm:px-6 lg:(py-20 px-8)`;
const Container = styled.div(outerContainer, containerSpacing);

const DisclosedOffsetFaqSection = ({
	title,
	description,
	items,
	hrefId,
	...divProps
}: FaqProps) => {
	return (
		<Container {...divProps}>
			<OffsetGrid>
				<Header
					hrefId={hrefId}
					title={<StyledSentence>{title}</StyledSentence>}
					description={
						description && (
							<div css={tw`prose prose-lg text-gray-500`}>
								<StructuredText data={description} />
							</div>
						)
					}
				/>
				<Items>
					<dl css={tw`space-y-12`}>
						{items.map((item) => (
							<Item key={item.question} {...item} />
						))}
					</dl>
				</Items>
			</OffsetGrid>
		</Container>
	);
};

export default DisclosedOffsetFaqSection;
