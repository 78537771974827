// https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import tw from 'twin.macro';
import StructuredText from '@datocms/MyStructuredText';
import Header from '@sections/CenteredHeader';
import type { FaqProps } from './types';

const DisclosableFaq = ({
	supertitle,
	title,
	description,
	items,
	hrefId,
	...props
}: FaqProps) => {
	return (
		<div tw="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8" {...props}>
			<div tw="max-w-3xl mx-auto space-y-6 divide-y-2 divide-gray-200">
				<div>
					<Header
						hrefId={hrefId}
						supertitle={supertitle}
						title={title}
						description={description}
					/>
				</div>
				<dl tw="space-y-6 divide-y divide-gray-200">
					{items.map((item) => (
						<Disclosure
							defaultOpen={typeof window === 'undefined'}
							as="div"
							key={item.question}
							tw="pt-6"
							itemScope
							itemProp="mainEntity"
							itemType="https://schema.org/Question">
							{({ open }) => (
								<>
									<dt tw="text-lg">
										<Disclosure.Button tw="text-left w-full flex justify-between items-start text-gray-400">
											<span tw="font-medium text-gray-900" itemProp="name">
												{item.question}
											</span>
											<span tw="ml-6 h-7 flex items-center">
												<ChevronDownIcon
													css={[
														open && typeof window !== 'undefined'
															? tw`-rotate-180`
															: tw`rotate-0`,
														tw`h-6 w-6 transform`,
													]}
													aria-hidden="true"
												/>
											</span>
										</Disclosure.Button>
									</dt>
									<Disclosure.Panel
										as="dd"
										tw="mt-2 pr-12"
										itemScope
										itemProp="acceptedAnswer"
										itemType="https://schema.org/Answer">
										<div
											itemProp="text"
											css={tw`prose max-w-none text-gray-500`}>
											<StructuredText data={item.answer} />
										</div>
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					))}
				</dl>
			</div>
		</div>
	);
};
export default DisclosableFaq;
